var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-4 mx-4" },
        [
          _c(
            "v-col",
            { attrs: { col2: "12", md: "2" } },
            [
              _c("date-picker", {
                attrs: { label: "From" },
                model: {
                  value: _vm.fromDate,
                  callback: function ($$v) {
                    _vm.fromDate = $$v
                  },
                  expression: "fromDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { col2: "12", md: "2" } },
            [
              _c("date-picker", {
                attrs: { label: "To" },
                model: {
                  value: _vm.toDate,
                  callback: function ($$v) {
                    _vm.toDate = $$v
                  },
                  expression: "toDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { col2: "12", md: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: { color: "primary", dark: "" },
                  on: { click: _vm.refresh },
                },
                [_c("v-icon", [_vm._v("mdi-refresh ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { col: "12" } },
            [
              _c("v-simple-table", {
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Time"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("User"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Type"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Action"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.auditList, function (item) {
                            return _c("tr", { key: item.id }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.format(
                                      new Date(item.timestamp * 1000),
                                      "PP p"
                                    )
                                  )
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(_vm.getUser(item)))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.recordTypes[item.recordType])
                                ),
                              ]),
                              _c("td", [_vm._v(_vm._s(item.description))]),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }